export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/(booking)": [4],
		"/(booking)/appointments": [5],
		"/(booking)/booking/success": [6],
		"/(booking)/details": [7,[2]],
		"/dev": [~14],
		"/(booking)/identification/error": [8],
		"/(booking)/identification/success": [9],
		"/(portal)/login": [10],
		"/(portal)/login/error": [11],
		"/(portal)/login/success": [12],
		"/(portal)/oma": [13,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';